import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

const FiltreAnnuaire = () => {
  const [categories, setCategories] = useState([]);

  const [numeros, setNumeros] = useState([]);

  const [loading, setLoading] = useState(true);

  const [search, setSearch] = useState('');

  const [filteredNumeros, setFilteredNumeros] = useState([]);

  const getCategorieData = () => {
    axios
      .get('/wp-json/wp/v2/categorienumero')
      .then(res => {
        setCategories(res.data);
      })
      .catch(e => console.log(e));
  };

  const getNumerosData = () => {
    setLoading(true);
    axios
      .get('/wp-json/wp/v2/numeroutile/?per_page=100&orderby=title&order=asc')
      .then(res => {
        setNumeros(res.data);
        setFilteredNumeros(res.data);
        setLoading(false);
      })
      .catch(e => console.log(e));
  };

  const filterByCategorie = e => {
    if (e.target.value == 'all') {
      setFilteredNumeros(numeros);
      return;
    }

    const numeroByCat = numeros.filter(numero => {
      return numero.categorienumero[0] == e.target.value;
    });
    setFilteredNumeros(numeroByCat);
  };

  const handleSearch = e => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const searchResult = numeros.filter(({ title }) => {
      return title.rendered.toLowerCase().includes(search.toLocaleLowerCase());
    });
    setFilteredNumeros(searchResult);
  }, [search]);

  useEffect(() => {
    getCategorieData();
    getNumerosData();
  }, []);

  return (
    <>
      <div className="filtre">
        <input
          type="text"
          name="search-annuaire"
          onChange={handleSearch}
          value={search}
          placeholder="Recherche par mot clef"
        />
        <select name="select-categorie" onChange={filterByCategorie}>
          <option value="all">Tous</option>
          {categories.map(cat => (
            <option key={cat.id} value={cat.id}>
              {cat.name} ({cat.count})
            </option>
          ))}
        </select>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div className="annuaire">
          {filteredNumeros.map(numero => (
            <article key={numero.id}>
              <header
                className="nom"
                dangerouslySetInnerHTML={{ __html: numero.title.rendered }}
              ></header>
              <div className="telephone">
                {numero.acf.telephones.map((tel,i) => (
                  <p key={i}><i className="fas fa-phone"></i>{tel.telephone}</p>
                ))}
              </div>
            </article>
          ))}
        </div>
      )}
    </>
  );
};

export default FiltreAnnuaire;

const Loading = () => (
  <div className="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

if (document.getElementById('filtre-annuaire')) {
  ReactDOM.render(
    <FiltreAnnuaire />,
    document.getElementById('filtre-annuaire')
  );
}
